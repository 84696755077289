import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { IImage } from '../../modules/reducers/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeModalState,
  deleteImage,
  handlePhotoswipeAction,
  openImageInFullscreenAction,
  setImageTitle,
  setSmallScreenImageTitle
} from '../../modules/actions';
import theme from '../../assets/css/theme';
import { getTranslationKey } from '../../modules/utils/helpers';
import { FaExpandArrowsAlt, FaMobileAlt } from 'react-icons/fa';
import { omit } from 'lodash';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import media from '../../assets/css/media';
import { getDeletionStatus } from '../../modules/selectors';
import { isMobile } from 'mobile-device-detect';

const ButtonsWrapper = styled(ButtonGroup)<{ isPhotoswipe?: boolean }>`
  position: absolute;
  bottom: ${({ isPhotoswipe }) => (isPhotoswipe ? '0' : '30px')};
  z-index: 2;
  display: ${({ isPhotoswipe }) => (isPhotoswipe ? 'block' : 'none')};
  button,
  a {
    width: ${({ isPhotoswipe }) => (isPhotoswipe ? '50px' : '25px')};
    height: ${({ isPhotoswipe }) => (isPhotoswipe ? '50px' : '25px')};

    i,
    svg {
      font-size: ${({ isPhotoswipe }) => (isPhotoswipe ? '18px' : '12px')};
      width: ${({ isPhotoswipe }) => (isPhotoswipe ? '18px' : '12px')};
      height: ${({ isPhotoswipe }) => (isPhotoswipe ? '18px' : '12px')};
      margin-top: 0 !important;
    }
  }
`;

const ButtonItem = styled(({ ...rest }) => {
  const btProps = omit(rest, 'isHidden');

  return <Button {...btProps} />;
})<{ isHidden: boolean }>`
  position: relative;
  background: ${theme.formsColors.secondBackground};
  color: ${theme.formsColors.secondColor};
  border: none;
  display: ${(props) => (props.isHidden ? 'none' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const StyledUncontrolledTooltip = styled(ReactTooltip)`
  z-index: 1700 !important;
  font-size: 12px !important;
  padding: 10px !important;
  max-width: 150px !important;
  @media all and (max-width: ${media.desktop_min}) {
    display: none !important;
  }
`;

export type ActionButtonsTypes =
  | 'title'
  | 'smallScreenTitle'
  | 'crop'
  | 'info'
  | 'download'
  | 'watermark'
  | 'delete'
  | 'fullscreen';

interface IProps {
  image: IImage;
  index?: number;
  hideButtons?: ActionButtonsTypes[];
  actionID?: string;
  galleriesImages: IImage[];
  isPhotoswipe?: boolean;
}

export const ImageCardActions = ({
  image,
  hideButtons = [],
  actionID,
  galleriesImages,
  isPhotoswipe
}: IProps) => {
  const dispatch = useDispatch();
  const { originalImageName, _collection, _id } = image;
  const titleId = `${actionID}_title_${image._id}`;
  const smallScreenTitleId = `${actionID}_small_screen_title_${image._id}`;
  const fullscreenId = `${actionID}_fullscreen_${image._id}`;
  const cropId = `${actionID}_tcrop_${image._id}`;
  const infoId = `${actionID}_tinfo_${image._id}`;
  const downloadId = `${actionID}_tdownload_${image._id}`;
  const waterID = `${actionID}_twater_${image._id}`;
  const deleteID = `${actionID}_tdelete_${image._id}`;

  const isImageDeletionInProcess = useSelector(getDeletionStatus);
  const setTitleImageHandler = () => dispatch(setImageTitle(image));
  const setSmallScreenTitleImageHandler = () => dispatch(setSmallScreenImageTitle(image));
  const checkIsButtonHidden = (buttonName: ActionButtonsTypes) => hideButtons?.includes(buttonName);
  const deleteImageHandler = () => {
    if (isImageDeletionInProcess) return;

    dispatch(deleteImage(image._id));
  };
  const preventPropagation = (e: any) => e.stopPropagation();
  const setWatermarkHandler = () => {
    dispatch(
      changeModalState({
        key: 'selectWaterMarkModal',
        state: true,
        modalData: {
          watermarkType: 'image',
          targetID: image._id,
          watermarkID: image._watermark
        }
      })
    );
  };
  const cropImageHandler = () => {
    dispatch(
      changeModalState({
        key: 'cropImageModal',
        state: true,
        modalData: {
          targetID: image._id
        }
      })
    );
  };

  const onOpenInFullscreen = () => {
    const currentIdx = galleriesImages.findIndex(({ _id }) => _id === image._id);

    dispatch(openImageInFullscreenAction({ images: galleriesImages, index: currentIdx }));
    dispatch(handlePhotoswipeAction({ image, index: currentIdx }));
  };

  return (
    <ButtonsWrapper
      onClick={preventPropagation}
      className="image-tools"
      isPhotoswipe={isPhotoswipe}
    >
      <ButtonItem
        size="sm"
        color="primary"
        onClick={setTitleImageHandler}
        isHidden={checkIsButtonHidden('title')}
        data-tip
        data-for={titleId}
      >
        <i className="icon-picture" />
        {!isMobile && (
          <StyledUncontrolledTooltip id={titleId} effect="solid">
            {getTranslationKey('gallerySettings.imageActions.titleImage')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
      <ButtonItem
        size="sm"
        color="primary"
        onClick={setSmallScreenTitleImageHandler}
        isHidden={checkIsButtonHidden('smallScreenTitle')}
        data-tip
        data-for={smallScreenTitleId}
      >
        <FaMobileAlt />
        {!isMobile && (
          <StyledUncontrolledTooltip id={smallScreenTitleId} effect="solid">
            {getTranslationKey('gallerySettings.imageActions.smallScreenTitleImage')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
      <ButtonItem
        size="sm"
        color="primary"
        onClick={cropImageHandler}
        isHidden={checkIsButtonHidden('crop')}
        data-tip
        data-for={cropId}
      >
        <i className="icon-frame" />
        {!isMobile && (
          <StyledUncontrolledTooltip id={cropId} effect="solid">
            {getTranslationKey('gallerySettings.imageActions.appIcon')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
      {!isPhotoswipe && (
        <ButtonItem
          id={fullscreenId}
          size="sm"
          color="primary"
          onClick={onOpenInFullscreen}
          isHidden={checkIsButtonHidden('fullscreen')}
          data-tip
          data-for={fullscreenId}
        >
          <FaExpandArrowsAlt />
          {!isMobile && (
            <StyledUncontrolledTooltip id={fullscreenId} effect="solid">
              {getTranslationKey('gallerySettings.imageActions.fullscreen')}
            </StyledUncontrolledTooltip>
          )}
        </ButtonItem>
      )}
      {!isPhotoswipe && (
        <ButtonItem
          size="sm"
          color="primary"
          isHidden={checkIsButtonHidden('info')}
          data-tip
          data-for={infoId}
        >
          <i data-cy="icon-info" className="icon-info" />
          {!isMobile && (
            <StyledUncontrolledTooltip id={infoId} effect="solid">
              {originalImageName}
            </StyledUncontrolledTooltip>
          )}
        </ButtonItem>
      )}
      <ButtonItem
        size="sm"
        color="primary"
        href={`${window.API_URL_DL}/api/image/download/${_collection}/${_id}?q=high`}
        isHidden={checkIsButtonHidden('download')}
        data-tip
        data-for={downloadId}
      >
        <i className="icon-cloud-download" />
        {!isMobile && (
          <StyledUncontrolledTooltip id={downloadId} effect="solid">
            {getTranslationKey('downloadOriginalImage')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
      <ButtonItem
        id={waterID}
        size="sm"
        color="primary"
        onClick={setWatermarkHandler}
        isHidden={checkIsButtonHidden('watermark')}
        data-tip
        data-for={waterID}
      >
        <i className="icon-drop" />
        {!isMobile && (
          <StyledUncontrolledTooltip id={waterID} effect="solid">
            {getTranslationKey('watermark.watermark')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
      <ButtonItem
        id={deleteID}
        size="sm"
        color="danger"
        onClick={deleteImageHandler}
        isHidden={checkIsButtonHidden('delete')}
      >
        <i className="icon-trash" data-cy="icon-trash" />
        {!isMobile && (
          <StyledUncontrolledTooltip id={deleteID} effect="solid">
            {getTranslationKey('gallerySettings.imageActions.delete')}
          </StyledUncontrolledTooltip>
        )}
      </ButtonItem>
    </ButtonsWrapper>
  );
};
