import React, { useEffect } from 'react';
// import { debounce } from 'lodash';
import { SelectItem, LabelItem } from '../../../components';
import ColorPickerField from './ColorPickerField';
import { createFontLink, getTranslationKey } from '../../../modules/utils';
import { ICollectionsReducerState } from '../../../modules/reducers';
import { useDispatch, useSelector, connect } from 'react-redux';
import { getTemplatesFonts, getTemplatesList } from '../../../modules/selectors/templates';
import { fetchTemplatesFonts } from '../../../modules/actions';
import * as actions from '../../../actions';
import { UncontrolledTooltip } from 'reactstrap';
import theme from '../../../assets/css/theme';
import { FaUndo } from 'react-icons/fa';
import { orderBy } from 'lodash';
import Select from 'react-select';

type Props = {
  design: ICollectionsReducerState['design'];
  templateId?: string;
  setDesign: (design: Partial<ICollectionsReducerState['design']>) => void;
  submitColor?: (...args: any[]) => void;
  availableRules: any;
  setUpgradeAccountOpenState: (obj: any) => void;
};

const DesignForm = ({
  design,
  setDesign,
  submitColor = () => {},
  templateId,
  availableRules,
  setUpgradeAccountOpenState
}: Props) => {
  const dispatch = useDispatch();
  const templateFonts = useSelector(getTemplatesFonts);
  const sortedTemplateFonts = orderBy(
    templateFonts,
    [(template) => template.name.toLowerCase()],
    ['asc']
  );
  const templates = useSelector(getTemplatesList);
  // @ts-ignore
  const template = templates.find((template) => template._id === templateId);
  const defaultValues = {
    font: template && template.defaultDesign ? template.defaultDesign.font : '',
    primaryColor: template && template.defaultDesign ? template.defaultDesign.primaryColor : '',
    secondaryColor: template && template.defaultDesign ? template.defaultDesign.secondaryColor : '',
    imageSpacing: template && template.imageSpacing ? template.imageSpacing : ''
  };

  useEffect(() => {
    if (templateFonts.length === 0) {
      dispatch(fetchTemplatesFonts());
    }
  }, [templateFonts]); // eslint-disable-line

  useEffect(() => {
    if (templateFonts.length > 0) createFontLink(templateFonts);
  }, [templateFonts]); // eslint-disable-line

  const setPrimaryColor = (color: string) => {
    if (!availableRules.canCustomimzeDesign) {
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    setDesign({
      variables: {
        primaryColor: color
      }
    });
  };

  const setSecondaryColor = (color: string) => {
    if (!availableRules.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({ key: 'upgradeModal', state: true });
    }

    setDesign({
      variables: {
        secondaryColor: color
      }
    });
  };

  const selectFont = ({ value: font }: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    setDesign({
      loadFonts: [font],
      variables: {
        font,
        headingFont: font
      }
    });
  };

  const onColorChange = (e: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    setPrimaryColor(e.hex);
  };

  const onColorChangeComplete = (e: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    submitColor({ primaryColor: e.hex });
  };

  const onSecondaryColorChange = (e: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    setSecondaryColor(e.hex);
  };

  const onSecondaryChangeComplete = (e: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    submitColor({ secondaryColor: e.hex });
  };

  const onImageSizingsChange = (imageSpacing: any) => {
    if (!availableRules?.canCustomimzeDesign) {
      // @ts-ignore
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'customDesign'
      });
    }

    setDesign({ imageSpacing });
  };

  const resetFont = () => {
    setDesign({
      loadFonts: [],
      variables: {
        font: '',
        headingFont: ''
      }
    });
  };

  const resetImageSpacing = () => setDesign({ imageSpacing: '' });

  const undoLink = (onClick: () => void, id: string) => (
    <a
      href="/"
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <FaUndo style={{ marginLeft: '10px' }} id={id} />
      <UncontrolledTooltip placement="right" target={id}>
        {getTranslationKey('collectionDesign.undo')}
      </UncontrolledTooltip>
    </a>
  );

  return (
    <>
      <div className="mt-3">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ paddingRight: '15px' }}
        >
          <div>
            <LabelItem htmlFor="font-select" isSmall isBold>
              {getTranslationKey('collectionDesign.font')}
            </LabelItem>
          </div>
          <div>{undoLink(resetFont, 'font')}</div>
        </div>
        <Select
          className="basic-single"
          id="font-select"
          value={{
            label: design.variables.font || defaultValues.font,
            value: design.variables.font || defaultValues.font
          }}
          onChange={selectFont}
          isSearchable
          name="font"
          theme={(theme) => ({
            ...theme,
            spacing: {
              ...theme.spacing,
              controlHeight: 30,
              baseUnit: 4
            }
          })}
          styles={{
            control: (styles, { isFocused }) => ({
              ...styles,
              borderRadius: '20px',
              fontFamily: design.variables.font || defaultValues.font,
              '&:hover': {
                borderColor: isFocused ? '#b076f7' : styles.borderColor
              },
              ...(isFocused
                ? {
                    borderColor: '#b076f7',
                    outline: 0,
                    boxShadow: '0 0 0 0.2rem rgb(108 13 225 / 25%)'
                  }
                : {})
            }),
            menu: (styles) => ({
              ...styles,
              zIndex: 999999
            }),
            option: (styles, { data }) => ({
              ...styles,
              fontFamily: data.value
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: 'none'
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              color: theme.commonColors.second,
              paddingTop: 6,
              paddingBottom: 7,
              '&:hover': {
                color: theme.commonColors.second
              }
            })
          }}
          options={[
            ...sortedTemplateFonts.map((font) => ({
              value: font.name,
              label: font.name
            })),
            { value: '', text: '' }
          ]}
        />
      </div>
      <div className="d-flex mt-3">
        <div className="mr-2">
          <ColorPickerField
            color={design.variables.primaryColor || defaultValues.primaryColor}
            id="primary-color-picker"
            label={getTranslationKey('collectionDesign.primaryColor')}
            labelActions={undoLink(() => {
              setDesign({ variables: { primaryColor: '' } });
              submitColor({ primaryColor: '' });
            }, 'primary-color')}
            placeholder={getTranslationKey('collectionDesign.pickPrimaryColor')}
            onColorChange={onColorChange}
            onColorChangeComplete={onColorChangeComplete}
          />
        </div>
        <ColorPickerField
          color={design.variables.secondaryColor || defaultValues.secondaryColor}
          id="secondary-color-picker"
          label={getTranslationKey('collectionDesign.secondaryColor')}
          labelActions={undoLink(() => {
            setDesign({ variables: { secondaryColor: '' } });
            submitColor({ secondaryColor: '' });
          }, 'secondary-color')}
          placeholder={getTranslationKey('collectionDesign.pickSecondaryColor')}
          onColorChange={onSecondaryColorChange}
          onColorChangeComplete={onSecondaryChangeComplete}
        />
      </div>
      <div className="mt-3">
        <SelectItem
          label={getTranslationKey('collectionDesign.imageSpacing')}
          labelActions={undoLink(resetImageSpacing, 'image-spacing')}
          value={design.imageSpacing || defaultValues.imageSpacing}
          placeholder={getTranslationKey('collectionDesign.imageSpacingPlaceHolder')}
          changeCallback={onImageSizingsChange}
          options={[
            {
              value: '',
              text: getTranslationKey('collectionDesign.imageSpacingPlaceHolder')
            },
            {
              value: 'small',
              text: getTranslationKey('collectionDesign.imageSpacingSmall')
            },
            {
              value: 'middle',
              text: getTranslationKey('collectionDesign.imageSpacingSmallMiddle')
            },
            {
              value: 'large',
              text: getTranslationKey('collectionDesign.imageSpacingSmallLarge')
            }
          ]}
        />
      </div>
    </>
  );
};

function mapStateToProps({ accessRules: { availableRules } }: { accessRules: any }) {
  return { availableRules };
}

export default connect(mapStateToProps, actions)(DesignForm);
