import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, putPaymentInfoAction } from '../../modules/actions';
import { getUser } from '../../modules/selectors';
import { useIsPaymentProblem } from '../../common/use-is-payment-problem';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { useHistory, useLocation } from 'react-router';
import { getAccessRules } from '../../modules/selectors/accessRules';
import { getTemplatesList } from '../../modules/selectors/templates';

const StripeMigrationAlert = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isUserClosedAlert, setIsUserClosedAlert] = useState(false);
  const { subscription } = useSelector(getUser);
  const { paymentProvider, subscriptions } = useSelector(getPaymentInfo);
  const { isPaymentProblem } = useIsPaymentProblem();
  const { planConfig } = useSelector(getAccessRules);
  const templates = useSelector(getTemplatesList);

  useEffect(() => {
    if (!templates.length) {
      dispatch(fetchTemplates());
    }
  }, [templates]);

  const coreSub = subscriptions.find((sub) => sub.subscriptionType === 'core');
  const daysUntilNextPayment = Math.floor(
    moment.duration(moment(coreSub?.nextPaymentAt, moment.ISO_8601).diff(new Date())).asDays()
  );

  const isOpen =
    !isUserClosedAlert &&
    !location.pathname.startsWith('/subscription/pricing') &&
    paymentProvider !== 'stripe' &&
    !!coreSub &&
    !isPaymentProblem &&
    (subscription.plan === 'starter' || subscription.plan === 'professional') &&
    daysUntilNextPayment >= 0 &&
    daysUntilNextPayment <= 14;

  return (
    <Alert
      id="stripe-migration-alert"
      className="cursor-pointer mb-0"
      isOpen={isOpen}
      dismissible
      toggle={(e) => {
        e.stopPropagation();
        setIsUserClosedAlert(true);
      }}
      onClick={() => {
        localStorage.setItem(
          'subscriptionConfig',
          JSON.stringify({
            plan: subscription.plan,
            billingCycle: subscription.intervall,
            videoStorage: subscription.videoPlan === 'free' ? '' : subscription.videoPlan,
            slideshows: subscription.slideshowsPlan === 'unlimited',
            templates: [],
            brandsAmount: planConfig.brands,
            domainsAmount: planConfig.domains,
            purchased_templates: []
          })
        );
        dispatch(putPaymentInfoAction({ showStripeMigrationNote: true }));
        history.push('/subscription/pricing/payment');
      }}
      color="warning"
    >
      <div>{getTranslationKey('subscription.stripe-migration-alert')}</div>
    </Alert>
  );
};

export default StripeMigrationAlert;
