import React from 'react';
import styled from 'styled-components';
import { LabelItem } from './LabelItem';
import { InputProps } from 'reactstrap';
import { InputStyled } from './InputStyled';
// @ts-ignore
import chevron from '../../assets/img/icons/icon-chevron-down.svg';

interface IWrapper {
  isInline: boolean;
  margin?: string;
}

const Wrapper = styled.div<IWrapper>`
  display: ${(props) => (props.isInline ? 'flex' : 'block')};
  margin: ${(props) => props.margin || '0 0 10px 0'};
  min-width: 200px;
`;

const SelectStyled = styled(InputStyled)`
  appearance: none;
  background: url(${chevron}) 95.5% center no-repeat;
  background-size: 12px;
`;

interface IProps extends InputProps {
  options: any[];
  label?: string;
  id?: string;
  isInline?: boolean;
  changeCallback: (value: string) => void;
  value: string | number;
  defaultOptionsText?: string;
  margin?: string;
  formTheme?: string;
  isDefaultDisabled?: boolean;
  labelActions?: any;
}

export const SelectItem = ({
  options = [],
  label,
  id,
  isInline = false,
  changeCallback,
  value,
  defaultOptionsText,
  margin,
  isDefaultDisabled,
  wrapperStyle = {},
  labelActions,
  ...props
}: IProps) => {
  const showLabel = label ? (
    <LabelItem htmlFor={id} isSmall isBold>
      {label}
    </LabelItem>
  ) : null;
  const defaultOption = defaultOptionsText ? (
    <option value="" aria-selected={false} disabled={isDefaultDisabled}>
      {' '}
      {defaultOptionsText}
    </option>
  ) : null;
  const changeHandler = (e: React.BaseSyntheticEvent) => changeCallback(e.currentTarget.value);

  return (
    <Wrapper isInline={isInline} margin={margin} style={wrapperStyle}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ paddingRight: '15px' }}
      >
        <div>{showLabel}</div>
        <div>{labelActions}</div>
      </div>
      <SelectStyled type="select" onChange={changeHandler} value={value} {...props}>
        {defaultOption}
        {options.map((option, key) => (
          <option
            key={key}
            value={option.value}
            aria-selected
            style={option.style}
            disabled={Boolean(option.isDisabled)}
          >
            {option.text}
          </option>
        ))}
      </SelectStyled>
    </Wrapper>
  );
};

export default SelectItem;
