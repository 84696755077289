import { createReducer } from 'redux-act';
import { IGalleryTag } from '../../../modules/types';
import {
  clearPortfolios,
  fetchPortfoliosFailed,
  changePortfoliosStateAction,
  fetchPortfoliosSuccess,
  uploadPortfolioImageActionProgressStart,
  uploadPortfolioImageActionProgressStop,
  fetchPortfoliosAction
} from '../../actions/portfolios';

interface ICustomDomain {
  domain: string;
  path: string;
  url: string;
}

export interface IPortfolio {
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly _id: string;
  readonly user: any;
  title: string;
  description: string;
  userBrand: string;
  customDomain: ICustomDomain;
  language: number;
  password: string;
  tags?: IGalleryTag[];
  expirationDate?: Date | null;
  titleImage?: ITitleImage;
  portfolioTags: any[];
  cdnDomain?: string;
}

export interface ITitleImage {
  imageId: string;
  publicImageKey: string;
  originalImageName: string;
  isUploading: boolean;
}

export interface IPortfolioReducerState {
  portfolios: IPortfolio[];
  portfoliosTotalCount: number | null;
  selected: IPortfolio;
  isLoading: boolean;
  paginationState: {
    skip: number;
    limit: number;
    sortBy: string;
    sortOrder: string;
    searchQuery: string;
  };
}

const initialState: IPortfolioReducerState = {
  portfolios: [],
  portfoliosTotalCount: null,
  selected: {
    _id: '',
    createdAt: '',
    updatedAt: '',
    user: '',
    title: '',
    description: '',
    userBrand: '',
    customDomain: { domain: '', path: '', url: '' },
    language: 0,
    password: '',
    portfolioTags: [],
    titleImage: {
      imageId: '',
      publicImageKey: '',
      originalImageName: '',
      isUploading: false
    }
  },
  isLoading: false,
  paginationState: {
    skip: 0,
    limit: 10,
    sortBy: '',
    sortOrder: 'desc',
    searchQuery: ''
  }
};

export const portfolios = createReducer<IPortfolioReducerState>({}, initialState);
portfolios.on(fetchPortfoliosAction, (state, payload) => ({
  ...state,
  isLoading: true,
  paginationState: {
    skip: payload.skip,
    limit: payload.limit,
    sortBy: payload.sortBy,
    sortOrder: payload.sortOrder,
    searchQuery: payload.searchQuery
  },
}));
portfolios.on(fetchPortfoliosSuccess, (state, payload) => ({
  ...state,
  portfolios: payload.portfolios,
  portfoliosTotalCount: payload.portfoliosTotalCount,
  isLoading: false,
  searchQuery: !!payload.searchQuery
}));
portfolios.on(changePortfoliosStateAction, (state, payload: Partial<IPortfolio>) => ({
  ...state,
  selected: {
    ...state.selected,
    ...payload
  }
}));
portfolios.on(uploadPortfolioImageActionProgressStart, (state) => ({
  ...state,
  selected: {
    ...state.selected,
    titleImage: {
      ...state.selected.titleImage,
      isUploading: true
    }
  }
}));
portfolios.on(uploadPortfolioImageActionProgressStop, (state) => ({
  ...state,
  selected: {
    ...state.selected,
    titleImage: {
      ...state.selected.titleImage,
      isUploading: false
    }
  }
}));
portfolios.on(fetchPortfoliosFailed, () => initialState);
portfolios.on(clearPortfolios, () => initialState);
