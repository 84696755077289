import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import { EditableTitleField } from '../EditableTitleField';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getShopCouponByIDSelector } from '../../modules/selectors';
import { DEFAULT_COUPON } from '../../modules/constants';
import { InputItem } from '../Forms';
import { partial } from 'lodash';
import { IShopCouponDiscount } from '../../modules/types/shop';
import DatePicker from '../DatePicker';
// import theme from 'assets/css/theme';
import { DiscountItem } from './DiscountItem';
import {
  createCoupon,
  updateCoupon,
  fetchProductGroupsAction,
  fetchProductsAction,
  fetchLaboratoriesAction
} from '../../modules/actions';

const SaveButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    min-width: 30%;
  }
`;

const MainConfigurationWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 50%;
    min-width: auto;
  }
`;

// const AddDiscountBlock = styled.div`
// 	width: 100%;
// 	height: 50px;
// 	border: 3px dotted ${ theme.commonColors.second };
// 	color: ${ theme.commonColors.second };
// 	font-size: 20px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	cursor: pointer;
//
// 	i {
// 		margin-right: 10px;
// 	}
// `;

const fieldValidations = (field: string, value: string | number) => {
  if (field === 'code') {
    const reg = new RegExp('[\\s/~!@$%^&*()?`]', 'gm'); // eslint-disable-line

    return (value as string).replace(reg, '').trim();
  }

  return value;
};

interface IProps {
  isOpen: boolean;
  closeCallback: () => void;
  editID: string;
  createName?: string;
}

// tslint:disable-next-line:max-func-body-length
export const EditCreateCouponModal: React.FC<IProps> = ({
  isOpen,
  closeCallback,
  editID,
  createName
}) => {
  const dispatch = useDispatch();
  const shopCoupon = useSelector(getShopCouponByIDSelector)(editID);
  const [coupon, setCoupon] = useState({ ...DEFAULT_COUPON });
  const { name, code, validUntilDate, discounts, id, minimumOrderValue } = coupon;
  useEffect(() => {
    dispatch(fetchProductGroupsAction());
    dispatch(fetchProductsAction());
    dispatch(fetchLaboratoriesAction());
  }, [dispatch]);
  useEffect(() => {
    if (createName?.length) {
      setCoupon({
        ...DEFAULT_COUPON,
        name: createName
      });
    }
  }, [createName]);
  useEffect(() => {
    if (isOpen && shopCoupon) {
      setCoupon(shopCoupon);
    }
  }, [shopCoupon, isOpen]);
  const closeModal = () => {
    closeCallback();
    setCoupon({ ...DEFAULT_COUPON });
  };
  const changeCouponHandler = (fieldName: string, value: string | number) => {
    setCoupon({
      ...coupon,
      [fieldName]: fieldValidations(fieldName, value)
    });
  };
  // const addDiscount = () => {
  // 	setCoupon( {
  // 		...coupon,
  // 		discounts: [...discounts, { ...DEFAULT_DISCOUNT }],
  // 	} )
  // }
  // const removeDiscount = (index: number) => {
  //   const filteredDiscounts = filter(discounts, (_, discountIndex) => index !== discountIndex);
  //   setCoupon({
  //     ...coupon,
  //     discounts: filteredDiscounts
  //   });
  // };

  const updateDiscount = (discount: IShopCouponDiscount, index: number) => {
    discounts[index] = discount;
    setCoupon({
      ...coupon,
      discounts
    });
  };
  const createUpdateCoupon = () => {
    if (!id) {
      dispatch(createCoupon(coupon));
    } else {
      dispatch(updateCoupon(coupon));
    }
    closeCallback();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      // toggle={ closeModal }
      wrapClassName="modal-dark"
      size="lg"
    >
      <ModalHeader toggle={closeModal}>
        <EditableTitleField
          text={name}
          submitChangedCallBack={partial(changeCouponHandler, 'name')}
          themeName="white"
        />
      </ModalHeader>
      <ModalBody>
        <MainConfigurationWrapper>
          <InputItem
            label={getTranslationKey('shop.coupons.codeLabel')}
            value={code}
            changeCallback={partial(changeCouponHandler, 'code')}
            margin="0 10px 0 0"
            withTooltip
            tooltipText={getTranslationKey('shop.coupons.couponTooltip')}
            id="code"
          />
          <DatePicker
            withTime
            date={validUntilDate}
            callback={partial(changeCouponHandler, 'validUntilDate')}
            placeholder={getTranslationKey('shop.coupons.currencyPlaceholder')}
            label={getTranslationKey('shop.coupons.validLabel')}
            withTooltip
            tooltipText={getTranslationKey('shop.coupons.validUntilToolTip')}
            id="validDate"
            minDate={new Date()}
          />
          <InputItem
            label={getTranslationKey('shop.coupons.minimumOrderValueLabel')}
            value={minimumOrderValue}
            type="number"
            changeCallback={(value) => changeCouponHandler('minimumOrderValue', parseInt(value))}
            placeholder="-"
            margin="0 0 0 10px"
            withTooltip
            tooltipText={getTranslationKey('shop.coupons.minimumOrderValueTooltip')}
            id="minimumOrderValue"
          />
        </MainConfigurationWrapper>
        <div>
          {discounts.map((discount, index) => (
            <DiscountItem
              discount={discount}
              key={index}
              index={index}
              updateDiscountCallback={updateDiscount}
            />
          ))}
        </div>
        {/* <AddDiscountBlock onClick={ addDiscount }> */}
        {/*	<i className="fa fa-icon fa-plus"/> { getTranslationKey( 'shop.coupons.addDiscount' ) } */}
        {/* </AddDiscountBlock> */}
      </ModalBody>
      <ModalFooter>
        <SaveButtonBlock>
          <Button
            size="md"
            color="success"
            onClick={createUpdateCoupon}
            className="btn btn-primary"
          >
            {getTranslationKey('galleryPresets.saveButton')}
          </Button>
        </SaveButtonBlock>
      </ModalFooter>
    </Modal>
  );
};
